import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { DATETIMES } from 'projects/sb-lib/src/lib/models/constants';

@Pipe({
  name: 'sbDate'
})
export class SbDatePipe implements PipeTransform {

  transform(value: any, type: string, timezone: string = null): string {
    const result = (timezone !== null) ? moment(value).tz(timezone) : moment(value);
    return (result.isValid()) ? result.format(DATETIMES[type]) : 'invalid date/time';
  }

}
