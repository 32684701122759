import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormGroup, FormControl, Validators, UntypedFormControl } from '@angular/forms';

import { VALIDATOR_PATTERN_NAME, VALIDATOR_PATTERN_MOBILE, VALIDATOR_PATTERN_EMAIL, VALIDATOR_PATTERN_ZIP, CANDIDATE_SOURCES } from '../../../../services/data.service';
import { EventModel } from '../../../../../../../sb-lib/src/lib/models/event.model';
import { FormhelperService } from '../../../../../../../sb-lib/src/lib/modules/forms/services/formhelper.service';
import { PreregModes } from '../../preregistration.component';
import { AppService } from 'projects/microsite/src/app/services/app.service';

export interface PreregInitDataType {
  firstname: string;
  lastname: string;
  mobile: string;
}

@Component({
	selector: 'app-personal-data',
	templateUrl: './personal-data.component.html',
	styleUrls: ['./personal-data.component.scss']
})
export class PersonalDataComponent implements OnInit, OnChanges {

	@Input() event: EventModel;
	@Input() data: any;
	@Input() errors: any;
  @Input() mode: string = PreregModes.REGISTRATION;
  @Input() init: PreregInitDataType = null;

	@Output() dataChange = new EventEmitter();

	form = new FormGroup({
		lastname: new FormControl('', [Validators.required, Validators.pattern(VALIDATOR_PATTERN_NAME)]),
		middlename: new FormControl(''),
		firstname: new FormControl('', [Validators.required, Validators.pattern(VALIDATOR_PATTERN_NAME)]),
		mobile: new FormControl('', [Validators.required, Validators.pattern(VALIDATOR_PATTERN_MOBILE)]),
		email: new FormControl('', [Validators.required, Validators.pattern(VALIDATOR_PATTERN_EMAIL)]),
		address_street: new FormControl('', [Validators.required, Validators.pattern(VALIDATOR_PATTERN_NAME)]),
		address_zip: new FormControl('', [Validators.required, Validators.pattern(VALIDATOR_PATTERN_ZIP)]),
		address_city: new FormControl('', [Validators.required, Validators.pattern(VALIDATOR_PATTERN_NAME)]),
		address_state: new FormControl(null, [Validators.required, Validators.pattern(VALIDATOR_PATTERN_NAME)]),
		candidate_source: new UntypedFormControl('', [Validators.required]),
		candidate_source_referral: new FormControl({ disabled: true, value: '' }),
		privacy: new FormControl('', Validators.requiredTrue),
	});

	optionsCandidateSources: Array<any> = this.fh.constantsToOptions(CANDIDATE_SOURCES);

  PreregModes = PreregModes;

  public states = this.app.getEnum('STATES', false);

	get show_referral(): boolean {
		return this.form.get('candidate_source').value && this.form.get('candidate_source').value.select_value === 'Referral';
	}

	constructor(
		private fh: FormhelperService,
    private app: AppService
	) { }

	ngOnInit() {
		this.form.get('candidate_source').valueChanges.subscribe(() => {
			if (this.show_referral === true) {
				this.form.get('candidate_source_referral').enable();
				this.form.get('candidate_source_referral').setValidators([Validators.required]);
			} else {
				this.form.get('candidate_source_referral').clearValidators();
				this.form.get('candidate_source_referral').disable();
			}
			this.form.updateValueAndValidity();
		});
	}

	ngOnChanges(values: any) {
		if (values.errors.currentValue !== undefined && values.errors.currentValue.email !== undefined) {
			this.form.get('email').setErrors(values.errors.currentValue.email);
		}
		if (values.errors.currentValue !== undefined && values.errors.currentValue.mobile !== undefined) {
			this.form.get('mobile').setErrors(values.errors.currentValue.mobile);
		}
	}

	setSlot(slot: any) {
		this.data.candidate.slot = slot;
	}

	next() {
		if (this.form.valid) {
			this.setData((this.event.is_searay === true) ? 4 : 2);
			this.dataChange.emit(this.data);
		} else {
			this.fh.validateAllFormFields(this.form);
		}
	}

	private setData(step: any) {
		this.data.step = step;
		this.data.candidate.personal = {
			lastname: this.form.get('lastname').value,
			firstname: this.form.get('firstname').value,
			mobile: this.fh.phoneToValue(this.form.get('mobile').value),
			email: this.form.get('email').value,
			address_street: this.form.get('address_street').value,
			address_zip: this.form.get('address_zip').value,
			address_city: this.form.get('address_city').value,
			address_state: this.form.get('address_state').value?.id || this.form.get('address_state').value,
			candidate_source: (this.form.get('candidate_source').value) ? this.form.get('candidate_source').value.select_value : null,
			candidate_source_referral: this.form.get('candidate_source_referral').value,
			privacy: this.form.get('privacy').value,
		};
		if (this.event.is_searay === true) {
			this.data.candidate.personal.middlename = this.form.get('middlename').value;
		}
	}

}
