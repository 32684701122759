import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { COUNTRY_NAME } from 'projects/sb-lib/src/lib/models/constants';
import { GoogleMapsService } from '../../../api/services/google-maps.service';
import { FormhelperService } from '../../services/formhelper.service';

@Component({
	selector: 'sb-forms-zipcity',
	templateUrl: './zipcity.component.html',
	styleUrls: ['./zipcity.component.scss']
})
export class ZipcityComponent implements OnChanges {

	@Input() zipControl: UntypedFormControl;
	@Input() cityControl: UntypedFormControl;
  @Input() stateControl: UntypedFormControl;
  @Input() states: { [key: string]: string } | null = null;
	@Input() readonly: boolean;
	@Input() validMode = 'touched';
	@Input() layout = 'compact';

	@Output() editClicked = new EventEmitter<void>();

	public busy = false;
  public optionsStates: Array<any> = [];

	constructor(
		private maps: GoogleMapsService,
    private fh: FormhelperService
	) {}

  ngOnChanges(): void {
    if (this.states !== null) {
      Object.keys(this.states).forEach(key => { // change to shortcut as select_value
        this.states[key] = key;
      });
      this.optionsStates = this.fh.constantsToOptions(this.states);
    }
  }

	onZipChanged(value: string) {
		this.busy = true;
		this.maps.getLocationData(value + ', ' + COUNTRY_NAME).then(result => {
			if (result.city !== null) {
				this.cityControl.setValue(result.city);
			}
			if (result.state !== null) {
        if (this.optionsStates.length > 0) {
          const state = this.optionsStates.find(s => s.id === result.state);
          if (state !== null) {
            this.stateControl?.setValue(state);
          }
        } else {
				  this.stateControl?.setValue(result.state);
        }
			}
		}, (error) => {
			this.cityControl.setValue('');
			this.stateControl?.setValue('');
		}).then(() => {
			this.busy = false;
		});
	}

}
