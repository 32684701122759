// @i18n

export const EVENTCODE_TLD = '@kncandidateday.com';
export const COUNTRY_NAME = 'United States';
export const COUNTRY_SHORTCUT = 'US';

export const MAX_EVENT_LENGTH = 14;

export const DATETIMES: any = {
  DATE_SHORT: 'MMM DD',
  DATE_LONG: 'MMM DD, YYYY',
  DATE_MONTH_YEAR: 'MMM YYYY',
  DATE_REVERSE: 'YYYY-MM-DD',
  TIME_SHORT: 'hh:mm A',
  TIME_LONG: 'hh:mm:ss A',
  DATETIME_SHORT: 'MMM DD, hh:mm A',
  DATETIME_LONG: 'MMM DD YYYY, hh:mm A',
  DATETIME_FULL_REVERSE: 'YYYY-MM-DD HH:mm:ss',
  DATE_PRIMEPICKER_LONG: 'M dd yy',
  DATE_PRIMEPICKER_MONTH_YEAR: 'y',
  HOUR_FORMAT: 12,
  FIRST_DAY_OF_WEEK: 1
}

export const USER_ROLES: any = {
	administrator: 'Administrator',
	genius: 'Genius',
	recruiter: 'Recruiter',
	'registration-clerk': 'Registration Clerk',
	'back-office': 'Back Office',
	management: 'Management',
};

export const META_ROLES: any = {
	user: 'User',
	admin: 'Administrator',
	superuser: 'Super User'
};

export const CURRENCIES_SHORT: any = {
	USD: '$',
	GBP: '£',
	EUR: '€'
}

export const EVENT_TYPES: any = {
	'candidate-day': 'CANDIDATEday',
	'candidate-friday': 'CANDIDATEfriday',
	'searay': 'SeaRay'
};

export const EVENT_STATUSES: any = {
	setup: 'Setup',
	standby: 'Standby',
	online: 'Published',
	running: 'Running',
	finished: 'Finished',
	archived: 'Archived',
};

export const EVENTDAY_STATUSES: any = {
	setup: 'Setup',
	standby: 'Standby',
	running: 'Running',
	finished: 'Finished',
	archived: 'Archived',
};

export const CANDIDATEEVENT_STATUSES: any = {
	registered: 'Registered',
	noshow: 'No Show',
	interview: 'Interview',
	finished: 'Finished',
};

export const CANDIDATEEVENT_INTERVIEW_STATUSES: any = {
	hired: 'Hired',
	pooled: 'Pooled',
	rejected: 'Rejected',
	declined: 'Declined',
};

export const CANDIDATEEVENT_LISTSTATUSES: any = {
	registered: 'Registered',
	ready: 'Ready',
	scheduled: 'Interview scheduled',
	noshow: 'No Show',
	interview: 'Interview',
	finished: 'Finished',
};

export const REASONS: any = {
	pooled: [
		'Job Location and Start date does not fit for candidate',
		'Recruiter ideal job profile is not available',
		'Candidate ideal job profile is not available',
		'Salary requirements of the candidate is too high'
	],
	rejected: [
		'Does not meet general minimum requirements',
		'Does not meet minimum qualifications - location',
		'Does not meet minimum qualifications - amount of experience',
		'Does not meet minimum qualifications - relevant experience',
		'Does not meet minimum qualifications - US work authorization',
		'Salary requirements too high'
	],
	declined: [
		'I don’t wish to answer',
		'Personal or Family reasons ',
		'Salary requirements are too low',
		'Benefits are not attractive',
		'Schedule does not work – working hours, shift etc.',
		'Prefer staying with the current employer',
		'Travel distance is too far',
		'Job Location is not appealing ',
	],
};

export const WORKFORCE: any = {
	'Blue collar': 'Blue collar',
	'White collar': 'White collar',
};

export const WORKFORCE_LEVEL: any = {
	1: 1,
	2: 2,
	3: 3,
};

export const US_SCREENING_PACKAGE = {
	'Standard Background + Drug Screen': 'Standard Background + Drug Screen',
	'Driver Package + Drug Screen': 'Driver Package + Drug Screen',
  'Driver Package + Drug Screen (THC) (MF)': 'Driver Package + Drug Screen (THC) (MF)',
  'Standard Background + Drug Screen (THC) (MH)': 'Standard Background + Drug Screen (THC) (MH)',
  'Standard Background + OT Drug Screen (MD)': 'Standard Background + OT Drug Screen (MD)',
  'Standard Background + OT Drug Screen (THC) (ME)': 'Standard Background + OT Drug Screen (THC) (ME)',
};

export const PAY_GROUP = {
	'K6 - KN Semi-Monthly': 'K6 - KN Semi-Monthly',
	'K2 - KN Weekly': 'K2 - KN Weekly',
};

export const ACTION_REASON = {
	'New Hire (NHR)': 'New Hire (NHR)',
	'Rehire (REH)': 'Rehire (REH)',
	'Transfer from Affiliate (HAF)': 'Transfer from Affiliate (HAF)',
};

export const REQ_EMPLOYMENT_TYPE = {
	Permanent: 'Permanent',
	Temporary: 'Temporary',
};

export const REQ_FULLPART_TIME = {
	'Full Time': 'Full Time',
	'Part Time': 'Part Time',
};

export const JOBTYPE_STATUS: any = {
	employee: 'employee',
	'middle management': 'middle management',
	executive: 'executive'
};

export const CLICKBOARDING_EXPORT_TYPES = {
	'single-on-hire': 'Single Candidate after Hiring',
	'all-after-event': 'All Candidates after the Event'
};
